/*
 *  AB Experiment: https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/confluence_show_not_tell/setup
 *  AA Experiment: https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/confluence_show_not_tell_aa/setup
 */

const experiments = {
	STATSIG_SNT_EXPERIMENT_NAME_AB: 'confluence_show_not_tell',
	STATSIG_SNT_EXPERIMENT_NAME_AA: 'confluence_show_not_tell_aa',
} as const;
export type ExperimentNameValues = (typeof experiments)[keyof typeof experiments];

export const featureGates = {
	STATSIG_SNT_FG_AB: 'show_not_tell_fg_ab',
	STATSIG_SNT_FG_AA: 'show_not_tell_fg_aa',
} as const;

export const EXPERIMENT_CONFIG = {
	...experiments,
	...featureGates,
} as const;

export const COHORTS = {
	NOT_ENROLLED: 'not_enrolled',
	CONTROL: 'control',
	TREATMENT: 'treatment',
} as const;

export type CohortValues = (typeof COHORTS)[keyof typeof COHORTS];

// will be used once we have the entry point wrapper done
export type FeatureGateConfigValues = (typeof featureGates)[keyof typeof featureGates];
