import type { PropsWithChildren, ReactNode } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import type { MessageFormatElement } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';

import Button, { ButtonGroup } from '@atlaskit/button';

import { SPAViewContext } from '@confluence/spa-view-context';
import { ShowNotTellExperimentCohortContext } from '@confluence/experiment-show-not-tell-feature-gates/entry-points/experiment-wrapper';

import type { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { LearnMoreButton, type LearnMoreButtonProps } from '../Upgrade/LearnMoreButton';
import type { UpgradeEditionButtonProps } from '../Upgrade/UpgradeEditionButton';
import { UpgradeEditionButton } from '../Upgrade/UpgradeEditionButton';
import { calculateTestId } from '../Upgrade/calculateTestId';
import { useEditionsAnalyticsContext } from '../Upgrade/AnalyticsProvider';

import type { LinkProps } from './ChangeEditionSectionMessageButtonsWrapper';
import { ChangeEditionSectionMessageButtonsWrapper } from './ChangeEditionSectionMessageButtonsWrapper';
import type { EditionSectionMessageProps } from './EditionSectionMessage';
import { EditionSectionMessage } from './EditionSectionMessage';

export enum SectionMessageButtonConfig {
	DEFAULT = 'default',
	UPGRADE_ONLY = 'upgradeOnly',
	NONE = 'none',
}

type InProductHelpProps = {
	inProductHelpId?: string;
	inProductHelpTopic?: string;
};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export type ChangeEditionSectionMessageProps = Pick<
	EditionSectionMessageProps,
	| 'appearanceType'
	| 'body'
	| 'footer'
	| 'title'
	| 'headerButton'
	| 'withTasteAndToneStyling'
	| 'buttonGroupAlignment'
> &
	Pick<UpgradeEditionButtonProps, 'onUpgradeButtonClick'> & {
		canShowUpgradeButton?: boolean;
		buttonConfig?: SectionMessageButtonConfig;
		grandfathered?: boolean;
		learnMoreLink?: Partial<LinkProps> & InProductHelpProps;
		onUpFlowOpen?(): void;
		secondaryLink?: LinkProps;
		source: string;
		targetEdition?: ConfluenceEdition;
		upgradeEditionActionSubjectId?: string;
	};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const ChangeEditionSectionMessage = ({
	canShowUpgradeButton = true,
	buttonConfig = SectionMessageButtonConfig.DEFAULT,
	upgradeEditionActionSubjectId = 'freeTryNow',
	grandfathered,
	source,
	onUpFlowOpen,
	secondaryLink,
	targetEdition,
	learnMoreLink,
	footer,
	headerButton,
	...props
}: ChangeEditionSectionMessageProps) => {
	const { createAnalyticsEvent } = useEditionsAnalyticsContext();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const showNotTellExperimentCohort = useContext(ShowNotTellExperimentCohortContext);

	const CTAAnalyticsAttributes = useMemo(() => {
		return {
			isSiteAdmin,
			isGrandfathered: Boolean(grandfathered),
			// TODO: clean up ticket in https://atl-growth.atlassian.net/browse/NOTELL-56
			sntCohort:
				showNotTellExperimentCohort === 'control' ? showNotTellExperimentCohort : undefined,
		};
	}, [grandfathered, isSiteAdmin, showNotTellExperimentCohort]);

	const generateCTAAnalyticsProps = (actionSubjectId: string) => ({
		actionSubjectId,
		source,
		attributes: CTAAnalyticsAttributes,
	});

	const triggerLinkClickAnalytics = (
		clickEventActionSubjectId: string,
		linkMessage: string | MessageFormatElement[] | undefined,
	) => {
		if (createAnalyticsEvent) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'link',
					actionSubjectId: clickEventActionSubjectId,
					source,
					attributes: {
						...CTAAnalyticsAttributes,
						linkMessage,
					},
				},
			}).fire();
		}
	};

	const handleSecondaryLinkClick = () => {
		const link = secondaryLink;
		if (link) {
			triggerLinkClickAnalytics(link.actionSubjectId, link.messageDescriptor.defaultMessage);
			if (typeof link.onClick === 'function') {
				link.onClick();
			}
		}
	};

	useEffect(() => {
		if (createAnalyticsEvent) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'changeEditionGatewayScreen',
					attributes: {
						...CTAAnalyticsAttributes,
						source,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, CTAAnalyticsAttributes, source]);

	const renderButtons = () => {
		return (
			<ChangeEditionSectionMessageButtonsWrapper
				isSiteAdmin={isSiteAdmin}
				canShowUpgradeButton={canShowUpgradeButton}
				secondaryLink={secondaryLink}
			>
				{({ showUpgradeTryNowButton, showLearnMore, learnMoreIsPrimaryCTA }) => {
					//
					// primary
					//
					let primary: ReactNode;

					const upgradeEditionButton = showUpgradeTryNowButton ? (
						<UpgradeEditionButton
							analyticsProps={generateCTAAnalyticsProps(upgradeEditionActionSubjectId)}
							onUpFlowOpen={onUpFlowOpen}
							targetEdition={targetEdition}
							{...props}
						/>
					) : null;
					if (upgradeEditionButton) {
						primary = upgradeEditionButton;
					}

					//
					// secondaries
					//
					let secondaries: ReactNode[] | undefined;

					if (buttonConfig !== SectionMessageButtonConfig.UPGRADE_ONLY || !isSiteAdmin) {
						// If the 'Try it free/now' button is to be shown, but it is not the
						// primary CTA, then make sure its appearance is appropriate for a
						// secondary:
						if (upgradeEditionButton && primary !== upgradeEditionButton) {
							secondaries = [
								React.cloneElement(upgradeEditionButton, {
									appearance: 'link',
								}),
							];
						} else {
							secondaries = getSecondaries(showLearnMore, learnMoreIsPrimaryCTA);
						}
					}

					return (
						<>
							{primary}
							{secondaries}
						</>
					);
				}}
			</ChangeEditionSectionMessageButtonsWrapper>
		);
	};

	const getSecondaries = (showLearnMore: boolean, learnMoreIsPrimaryCTA: boolean) => {
		const withIcon = Boolean(learnMoreLink?.icon);
		const learnMoreAppearance =
			withIcon || learnMoreIsPrimaryCTA ? learnMoreLink?.appearance || 'default' : 'link';

		const learnMoreButton = showLearnMore
			? renderLearnMoreLink({
					appearance: learnMoreAppearance,
				})
			: null;

		const secondaryLinkComponent = secondaryLink ? (
			<Button
				key="secondLink"
				appearance={secondaryLink.appearance || 'default'}
				href={secondaryLink.href ? secondaryLink.href : undefined}
				id="secondaryLink"
				spacing="default"
				target={secondaryLink.target || '_blank'}
				onClick={handleSecondaryLinkClick}
				testId={calculateTestId({
					source,
					actionSubjectId: secondaryLink.actionSubjectId,
				})}
			>
				<FormattedMessage {...secondaryLink.messageDescriptor} />
			</Button>
		) : null;

		return secondaryLink?.appearance === 'primary' && learnMoreAppearance !== 'primary'
			? [secondaryLinkComponent, learnMoreButton]
			: [learnMoreButton, secondaryLinkComponent];
	};

	const renderLearnMoreLink = ({
		children,
		...learnMoreProps
	}: PropsWithChildren<Partial<Omit<LearnMoreButtonProps, 'onClick'>>>) => {
		return (
			<LearnMoreButton
				key="learnMore"
				analyticsProps={generateCTAAnalyticsProps(
					learnMoreLink?.actionSubjectId || 'freeLearnMore',
				)}
				appearance={learnMoreLink?.appearance || 'default'}
				onUpFlowOpen={onUpFlowOpen}
				targetEdition={targetEdition}
				inProductHelpId={learnMoreLink?.inProductHelpId}
				inProductHelpTopic={learnMoreLink?.inProductHelpTopic}
				href={learnMoreLink?.href}
				icon={learnMoreLink?.icon}
				ariaLabel={learnMoreLink?.ariaLabel}
				{...learnMoreProps}
				onClick={learnMoreLink?.onClick}
			>
				{children !== undefined ? (
					children
				) : learnMoreLink?.messageDescriptor ? (
					<FormattedMessage {...learnMoreLink.messageDescriptor} />
				) : undefined}
			</LearnMoreButton>
		);
	};

	return (
		<EditionSectionMessage
			actions={
				buttonConfig !== SectionMessageButtonConfig.NONE ? (
					<ButtonGroup>{renderButtons()}</ButtonGroup>
				) : undefined
			}
			edition={targetEdition}
			footer={isSiteAdmin && footer ? <>{footer}</> : null}
			headerButton={<ButtonGroup>{headerButton}</ButtonGroup>}
			{...props}
		/>
	);
};
