import { EXPERIMENT_CONFIG } from '../utils';

import { useIsValidAudience } from './useIsValidAudience';
import { useExperimentEnrollment } from './useExperimentEnrollment';
import { useFireExposureEvents } from './useFireExposureEvents';

type ShowNotTellFGExperiment = {
	cohort: string;
	renderTreatment: boolean;
};

type UseShowNotTellFGExperimentProps = {
	isExperimentEnabled: boolean;
	touchpointId: string;
	skipExposureEvents?: boolean;
};

/**
 * This hook is used to determine the enrollment status of the user in the A/A experiment.
 * It returns the user's cohort and whether they are in the treatment or control group.
 *
 * @returns {ShowNotTellFGExperiment} - The user's cohort and whether they are in the treatment or control group.
 */

export const useShowNotTellFGAAExperiment = ({
	isExperimentEnabled,
	touchpointId,
}: UseShowNotTellFGExperimentProps): ShowNotTellFGExperiment => {
	const { cohort, isEnrolled } = useExperimentEnrollment(
		EXPERIMENT_CONFIG.STATSIG_SNT_EXPERIMENT_NAME_AA,
	);

	// fire exposure events here if audience is valid and we are enrolled
	const isValidAudience = useIsValidAudience({ skip: !isExperimentEnabled || !isEnrolled });
	const isAAExperimentValid = isEnrolled && isValidAudience;
	const { fireExposureEvents } = useFireExposureEvents({
		skip: !isAAExperimentValid,
		experimentName: EXPERIMENT_CONFIG.STATSIG_SNT_EXPERIMENT_NAME_AA,
		touchpointId,
	});

	fireExposureEvents();
	return {
		cohort,
		// never render treatment for A/A testing
		renderTreatment: false,
	};
};

export const useShowNotTellFGABExperiment = ({
	isExperimentEnabled,
	touchpointId,
	skipExposureEvents,
}: UseShowNotTellFGExperimentProps): ShowNotTellFGExperiment => {
	const { cohort, isEnrolled, isTreatment } = useExperimentEnrollment(
		EXPERIMENT_CONFIG.STATSIG_SNT_EXPERIMENT_NAME_AB,
	);
	const isValidAudience = useIsValidAudience({ skip: !isExperimentEnabled || !isEnrolled });

	const { fireExposureEvents } = useFireExposureEvents({
		skip: Boolean(skipExposureEvents) || !(isEnrolled && isValidAudience),
		experimentName: EXPERIMENT_CONFIG.STATSIG_SNT_EXPERIMENT_NAME_AB,
		touchpointId,
	});

	fireExposureEvents();
	return {
		cohort,
		renderTreatment: isValidAudience && isTreatment,
	};
};
