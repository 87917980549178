import type { ComponentType } from 'react';
import React, { useContext } from 'react';

import { SPAViewContext } from '@confluence/spa-view-context';

export type WithIsSiteAdminProps = {
	isSiteAdmin: boolean;
};

export function withIsSiteAdmin<P>(
	WrappedComponent: ComponentType<P & WithIsSiteAdminProps>,
): ComponentType<P> {
	return function WithIsSiteAdminComponent(props) {
		const { isSiteAdmin, error, loading } = useContext(SPAViewContext);

		if (error || loading) return null;

		return <WrappedComponent {...props} isSiteAdmin={isSiteAdmin} />;
	};
}
