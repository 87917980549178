import { useSessionData, ConfluenceEdition } from '@confluence/session-data';

/**
 * This hook is used to determine if the current user is in the valid audience for the feature.
 * It returns true if the user is in the valid audience, otherwise false. Since we only have 1
 * conditional and no data fetching to determine the audience it is a little overkill but hoping
 * we can use it as a pattern in future experiments
 *
 * @param {boolean} skip - If true, the hook will skip the validation and return false.
 * @returns {boolean} - If the user is in the valid audience, return true. Otherwise, return false.
 */
export const useIsValidAudience = ({ skip }: { skip: boolean }) => {
	const { edition } = useSessionData();
	return skip ? false : edition?.toUpperCase() === ConfluenceEdition.FREE;
};
