import { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { type ExperimentNameValues, COHORTS } from '../utils';

import { useExperimentEnrollment } from './useExperimentEnrollment';

export const useFireExposureEvents = ({
	skip,
	experimentName,
	touchpointId,
}: {
	skip: boolean;
	experimentName: ExperimentNameValues;
	touchpointId: string;
}) => {
	const { edition } = useSessionData();
	const { cohort } = useExperimentEnrollment(experimentName);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);

	// eslint-disable-next-line confluence-feature-gating/static-feature-gates
	const [, fireExperimentExposure] = UNSAFE_noExposureExp(experimentName);

	const fireExposureEvents = useCallback(() => {
		if (!edition || !cohort || skip || cohort === COHORTS.NOT_ENROLLED) {
			return;
		}
		// fire statsig exposure event
		void fireExperimentExposure();
		// fire backup gasv3 exposure event
		// https://data-portal.internal.atlassian.com/analytics/registry/68915
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'exposed',
				actionSubject: 'experiment',
				actionSubjectId: `${experimentName}-exposed`,
				attributes: {
					edition,
					cohort,
					touchpointId,
					isSiteAdmin,
				},
				source: experimentName,
			},
		}).fire();
	}, [
		cohort,
		createAnalyticsEvent,
		edition,
		experimentName,
		isSiteAdmin,
		skip,
		touchpointId,
		fireExperimentExposure,
	]);

	return { fireExposureEvents };
};
